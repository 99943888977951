.naics-search-input-container {
    padding-top: 33vh;
    position: relative;
    display: block;
}

.naics-search-input-container label, input {
    display: block;
    font-size: 16px;
}

.naics-search-input-container input {
    padding: 8px;
    width: 100%;
}


.naics-search-input-container .options.show {
    display: block !important;
}

.naics-search-input-container .options {
    position: absolute;
    display: none;
    top: 100%;
    background: #FFF;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 10px 10px 37px 0px rgba(0,0,0,0.51);
    -webkit-box-shadow: 10px 10px 37px 0px rgba(0,0,0,0.51);
    -moz-box-shadow: 10px 10px 37px 0px rgba(0,0,0,0.51);
}

.naics-search-input-container .options button {
    display: block;
    border: 0;
    background: #EEE;
    border: 1px solid #DDD;
    padding: 8px;
    width: 100%;
    font-size: 16px;
    text-align: left;
    cursor: pointer;
}
.naics-search-input-container .options button:hover {
    background: #DDD;
}
.naics-search-input-container .options span {
    display: inline-block;
    width: 200px;
    max-width: 90%;
    color: #BBB;
    font-size: 16px;
}
