body {
    font-family: sans-serif;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bold {
    font-weight: bold;
}

details {
    cursor: pointer;
}
details summary {
    font-size: 12px;
    line-height: 12px;
}
details ul li {
    font-size: 12px;
    line-height: 12px;
}
.analyze-btn {
    width: 100%;
    margin: auto;
    display: block;
    height: 55px;
    font-size: 16px;
    margin-top: 90px;
    cursor: pointer;
    border-radius: 12px;
    border: 1px solid #B4B4B4;
}
.analyze-btn:hover:not([disabled]) {
    background: #DDD;
}

.error-feedback {
    color:  #ff4d4d;
    margin: 32px auto 0 auto;
}

.App {
    width: 750px;
    max-width: 90%;
    margin: auto;
}
