.one-selected-code {
    font-size: 14px;
    background: #DDD;
    height: 44px;
    line-height: 44px;
    vertical-align: middle;
    display: inline-block;
    padding: 0 18px;
    border-radius: 30px;
    margin: 8px 8px 0 0;
}

.one-selected-code button {
    border: 0;
    background: none;
    height: 44px;
    margin: 0 0 0 8px;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 4px;
}

.one-selected-code button:hover {
    transform: scale(1.1);
}
