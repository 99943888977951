.table-title {
    text-align: center;
    margin-top: 64px;
    margin-bottom: 0;
}

.results-table {
    width: 100%;
}
.results-table td,
.results-table th {
    font-size: 12px;
    text-align: left;
    padding: 6px;
}

.results-table th {
    font-weight: bold;
    text-align: center;
}

.results-table tr:nth-child(odd) td {
    background-color:  #EEE;
}
